<template>
  <div data-app>
    <div
      v-if="Number(currentUserPersonalInfo.perfil_id) === 1"
      class="card card-custom card-stretch gutter-b"
    >
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div class="col-md-3">
            <v-autocomplete
              v-model="mandante"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              return-object
              label="Seleccione mandante"
              dense
              @change="getNormalizations"
            ></v-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body p-0">
        <div class="row pt-0">
          <v-col cols="12">
            <v-data-table
              :headers="headersNormalizations"
              :items="normalizations"
              class="table-striped elevation-1"
              :hide-default-footer="true"
              :items-per-page="pageSize"
              :sort-options="sortOptions"
              @update:options="sortColumnsHandler"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Listado de Normalizaciones</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    color="success"
                    class="mb-2 mr-1"
                    v-on:click="getNormalizations()"
                    :disabled="!mandante"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 notTransparent"
                    v-on:click="$refs['AddressNormalizationModal'].showModal()"
                    :disabled="!mandante"
                  >
                    Nuevo
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`item.original`]="{ item }">
                <div style="display: inline-flex">
                  <v-icon v-on:click="downloadFile(item.uniqueID, '1')"
                    >mdi-file-excel</v-icon
                  >
                </div>
              </template>
              <template v-slot:[`item.normalizado`]="{ item }">
                <div style="display: inline-flex">
                  <v-icon
                    v-if="item.estado === 'OK NORMALIZADO'"
                    v-on:click="downloadFile(item.uniqueID, '2')"
                    >mdi-file-excel</v-icon
                  >
                </div>
              </template>
              <template v-slot:[`item.fecha`]="{ item }">
                <div style="display: inline-flex">
                  <span class="d-none">{{ item.fecha }}</span>
                  {{ formatDate(item.fecha) }}
                </div>
              </template>
            </v-data-table>
            <v-col cols="12" sm="12">
              <v-row>
                <v-col cols="4" sm="2">
                  <v-select
                    v-model="pageSize"
                    :items="pageSizes"
                    label="Filas por página"
                    @change="handlePageSizeChange"
                  ></v-select>
                </v-col>
                <v-col cols="8" sm="10">
                  <v-pagination
                    v-model="page"
                    :length="totalPages"
                    @input="handlePageChange"
                    total-visible="7"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </div>
      </div>
    </div>
    <AddressNormalizationModal
      ref="AddressNormalizationModal"
      :getNormalizations="getNormalizations"
      :mandante="mandante"
    />
  </div>
</template>
<script>
import AddressNormalizationModal from "./AddressNormalizationModal.vue";
import { downloadExcelFile } from "../../../helpers/xlsx";
import {
  GET_NORMALIZATIONS,
  DOWNLOAD_NORMALIZATION,
  DOWNLOAD_ORIGINAL_NORMALIZATION,
} from "@/core/services/store/address-normalization.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "AddressNormalizationList",
  components: {
    AddressNormalizationModal,
  },
  data() {
    return {
      showModal: false,
      normalizations: [],
      headersNormalizations: [
        {
          text: "OT",
          align: "center",
          value: "uniqueID",
        },
        {
          text: "Titulo",
          value: "titulo",
        },
        {
          text: "Fecha",
          value: "fecha",
        },
        {
          text: "Original",
          value: "original",
          sortable: false,
        },
        {
          text: "Registros",
          value: "registros",
          align: "center",
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
        },
        {
          text: "Normalizado",
          value: "normalizado",
          sortable: false,
        },
      ],
      search: "",
      page: 1,
      totalPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      sortBy: "uniqueID",
      sortDesc: true,
      sortOptions: {
        sortBy: ["uniqueID"],
        sortDesc: [true],
      },
      awaitingSearch: false,
      mandantes: [],
      mandante: null,
    };
  },
  created() {
    const oVue = this;
    const user = oVue.currentUserPersonalInfo;
    if (Number(user.perfil_id) === 1) {
      oVue.getMandantes();
    } else {
      oVue.mandante = {
        mandanteID: user.mandanteID,
        mandanteNombre: user.mandante,
      };
      oVue.getNormalizations();
    }
  },
  methods: {
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.mandantes = [];
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        oVue.mandantes = mandantes;
        KTUtil.removeLoading();
      });
    },
    getNormalizations() {
      KTUtil.setLoading();
      const oVue = this;
      oVue.normalizations = [];
      const sortBy = oVue.sortOptions.sortBy || null;
      const sortDesc = oVue.sortOptions.sortDesc;

      const state = {
        page: oVue.page,
        items_per_page: oVue.pageSize,
        sort: sortBy,
        order: sortDesc ? "desc" : "asc",
        search: oVue.search,
        report: 0,
      };
      const mandante = oVue.mandante?.mandanteID;
      const data = {
        state,
        mandante,
      };
      oVue.$store
        .dispatch(GET_NORMALIZATIONS, data)
        .then(({ data, payload }) => {
          oVue.normalizations = data;
          oVue.totalPages = payload?.pagination?.last_page;
          KTUtil.removeLoading();
        });
    },
    async downloadNormalization(id, type) {
      KTUtil.setLoading();
      let oVue = this;
      let url = DOWNLOAD_NORMALIZATION;
      if (type === "1") url = DOWNLOAD_ORIGINAL_NORMALIZATION;
      const normalization = await oVue.$store.dispatch(url, id);

      KTUtil.removeLoading();
      return normalization;
    },
    async downloadFile(id, type) {
      const data = await this.downloadNormalization(id, type);
      let filename = "original";

      const header = [];

      if (type === "2") {
        filename = "normalizado";
        header.push(
          { text: "id", value: "id" },
          { text: "metodo_normalizacion", value: "metodo" },
          { text: "estado", value: "estado" },
          { text: "rut", value: "rut" },
          { text: "id_dir", value: "id_dirOriginal" },
          { text: "dir_ori", value: "dirOriginal" },
          { text: "comuna_ori", value: "comunaOriginal" },
          { text: "dir_formateada_new", value: "direccionCompletaNormalizada" },
          { text: "comuna_new", value: "comunaNormalizada" },
          { text: "lat_new", value: "latitud" },
          { text: "long_new", value: "longitud" }
        );
      } else {
        header.push(
          { text: "rut", value: "rut" },
          { text: "id", value: "id_dir" },
          { text: "calle", value: "dir" },
          { text: "numero", value: "nro" },
          { text: "comuna", value: "comuna" }
        );
      }

      downloadExcelFile(filename, header, data);
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getNormalizations();
    },
    handlePageChange(value) {
      this.page = value;
      this.getNormalizations();
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD-MM-YYYY HH:mm:ss");
    },
    sortColumnsHandler(options) {
      this.sortOptions.sortBy = options.sortBy?.[0] || "uniqueID";
      this.sortOptions.sortDesc = options.sortDesc?.[0] || true;

      this.getNormalizations();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  watch: {
    search: function () {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getNormalizations();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
};
</script>
