<template>
  <div data-app>
    <AddressNormalizationList />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AddressNormalizationList from "../components/address-normalization/AddressNormalizationList.vue";
export default {
  name: "AddressNormalization",
  components: {
    AddressNormalizationList,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Normalización Direcciones", route: "address-normalization" },
    ]);
  },
};
</script>
