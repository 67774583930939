<template>
  <div>
    <b-modal
      v-if="!isLoading"
      ref="addressNormalizationModal"
      title="Nueva normalización"
      size="xl"
      hide-footer
    >
      <form class="form">
        <div class="pb-5">
          <v-row>
            <v-col cols="12" class="d-flex justify-content-center">
              <h3>
                {{ mandante ? mandante.mandanteNombre : "" }}
              </h3>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="titulo"
                label="Titulo"
                maxlength="50"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="type"
                :items="types"
                item-text="description"
                item-value="id"
                label="Tipo de normalización"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12">
              <vue2Dropzone
                id="dz"
                ref="dz"
                :options="dropzoneOption"
              ></vue2Dropzone>
              <a
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 2px;
                "
                href="files/norm.xlsx"
                target="_blank"
              >
                <v-icon style="font-size: 18px; margin-right: 5px"
                  >mdi-cloud-upload</v-icon
                >
                <span style="font-size: 14px"
                  >Descargar archivo de ejemplo</span
                >
              </a>
            </v-col>
          </v-row>
          <div class="d-flex justify-content-between pt-10 float-right">
            <v-btn
              @click="saveNormalization()"
              color="primary"
              dark
              class="font-weight-bold text-uppercase px-9 py-4 notTransparent"
              :disabled="!addresses || addresses.length === 0 || !titulo.trim()"
            >
              Enviar
            </v-btn>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import {
  SAVE_NORMALIZATION,
  GET_TYPES,
} from "@/core/services/store/address-normalization.module";
import XLSX from "sheetjs-style";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { isNotEmpty } from "../../../helpers/helper";
export default {
  name: "AddressNormalizationModal",
  props: {
    getNormalizations: Function,
    mandante: Object,
  },
  components: {
    vue2Dropzone,
  },
  data() {
    return {
      isLoading: false,
      addresses: [],
      dropzoneOption: null,
      titulo: "",
      type: "",
      types: [],
    };
  },
  created() {
    const oVue = this;
    oVue.dropzoneOption = {
      dictDefaultMessage: "Arrastre su archivo o haga click acá para subirlo.",
      dictRemoveFile: "Quitar",
      url: "/",
      paramName: "file",
      maxFiles: 1,
      maxFilesize: 30,
      addRemoveLinks: true,
      acceptedFiles: ".doc,.docx,.xls,.xlsx,.csv,.pdf",
      autoProcessQueue: false,
      init: function () {
        this.on("addedfile", function (file) {
          const reader = new FileReader();
          reader.onload = function (event) {
            const data = event.target.result;
            const wb = XLSX.read(data, { type: "array" });
            const addresses = XLSX.utils.sheet_to_json(
              wb.Sheets[wb.SheetNames[0]]
            );
            oVue.addresses = addresses;
          };
          reader.readAsArrayBuffer(file);
        });
        //eslint-disable-next-line no-unused-vars
        this.on("removedfile", function () {
          oVue.addresses = [];
        });
      },
      maxfilesexceeded: function (files) {
        this.removeAllFiles();
        this.addFile(files);
      },
    };
  },
  methods: {
    async showModal() {
      KTUtil.setLoading();

      const types = await this.$store.dispatch(GET_TYPES);
      this.types = types;

      KTUtil.removeLoading();

      this.$refs["addressNormalizationModal"].show();
    },
    saveNormalization() {
      let oVue = this;
      oVue.setLoading();
      const addresses = oVue.addresses;
      const titulo = oVue.titulo;
      const type = oVue.type;

      if (!titulo.trim()) {
        Swal.fire({
          title: `Error al guardar la normalización`,
          text: `Debe ingresar un titulo valido`,
          icon: "error",
        });
        oVue.removeLoading();
        return;
      }

      if (type === "") {
        Swal.fire({
          title: `Error al guardar la normalización`,
          text: `Debe seleccionar un tipo de normalización`,
          icon: "error",
        });
        oVue.removeLoading();
        return;
      }

      let canSave = true;

      for (let i = 0; i < addresses.length; i++) {
        const { rut, id, calle, comuna } = addresses[i];
        if (
          (!isNotEmpty(rut) && !isNotEmpty(id)) ||
          !isNotEmpty(calle) ||
          !isNotEmpty(comuna)
        ) {
          Swal.fire({
            title: `Error al guardar la normalización`,
            text: `El registro ${i + 1} no posee todos los campos`,
            icon: "error",
          });
          canSave = false;
          break;
        }
      }

      if (!canSave) {
        oVue.removeLoading();
        return;
      }

      const data = {
        mandante: oVue.mandante.mandanteID,
        datos: addresses.map((address) => {
          return {
            rut: address.rut || "",
            id_dir: address.id || "",
            dir: address.calle || "",
            nro: address.numero || "",
            comuna: address.comuna || "",
          };
        }),
        titulo,
      };

      oVue
        .saveNormalizationData(data, type)
        .then((response) => {
          if (response.result) {
            Swal.fire({
              title: "¡Enhorabuena!",
              text: `La normalización fue guardada exitosamente`,
              icon: "success",
            });
            oVue.getNormalizations();
            this.$refs["addressNormalizationModal"].hide();
          } else {
            Swal.fire({
              title: `Error al guardar la normalización`,
              text: response.message,
              icon: "error",
            });
          }
        })
        .finally(() => {
          oVue.removeLoading();
        });
    },
    async saveNormalizationData(data, type) {
      let oVue = this;
      const response = await oVue.$store.dispatch(SAVE_NORMALIZATION, {
        data,
        type,
      });
      return response;
    },
    setLoading() {
      this.isLoading = true;
      KTUtil.setLoading();
    },
    removeLoading() {
      this.isLoading = false;
      KTUtil.removeLoading();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>
