import XLSX from "sheetjs-style";

export function downloadExcelFile(filename, header, data) {
  const wb = (XLSX.WorkBook = XLSX.utils.book_new());
  wb.SheetNames.push(filename);

  const aHeader = header.filter((aData) => aData.text !== "");
  const aDataExcel = [aHeader.map((aData) => aData.text)];

  data.forEach((aRow) => {
    const aDataTmp = [];
    aHeader.forEach((aHeader) => {
      if (typeof aHeader.render === "undefined") {
        aDataTmp.push(aRow[aHeader.value]);
      } else {
        aDataTmp.push(aHeader.render(aRow));
      }
    });
    aDataExcel.push(aDataTmp);
  });

  const ws = XLSX.utils.aoa_to_sheet(aDataExcel);
  wb.Sheets[filename] = ws;

  const aDate = new Date();
  const sDate =
    aDate.getFullYear() + "" + (aDate.getMonth() + 1) + "" + aDate.getDate();
  const sTime =
    aDate.getHours() + "" + aDate.getMinutes() + "" + aDate.getSeconds();

  return XLSX.writeFile(wb, `${filename}_${sDate}${sTime}.xlsx`);
}
